<template>
  <div class="elite-tabs-wrapper-content">
    <div class="col-12">

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.NAME") }}</dt>
        <dd class="col-sm-8">
          {{ file.name }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.EXCERPT") }}</dt>
        <dd class="col-sm-8">
          {{file.excerpt}}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("CONTRACTS.SIGNATURE_PAGE") }}</dt>
        <dd class="col-sm-8">
          {{ file.signature_page }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("CONTRACTS.SIGNATURE_TYPE") }}</dt>
        <dd class="col-sm-8">
          {{ file.signature_type}}
        </dd>
      </dl>

      <dl class="row" v-if="file.is_template">
        <dt class="col-sm-4">{{ $t("CONTRACTS.TEMPLATE_NAME") }}</dt>
        <dd class="col-sm-8">
          {{ file.template_name }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
        <dd class="col-sm-8">
          {{ file.created_at | moment("LLLL") }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
        <dd class="col-sm-8">
          {{ file.updated_at | moment("LLLL") }}
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
export default {
  name: "file-view-modal-global",

  props: ["file"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {
    fileUpdated() {
      this.$emit("fileUpdated", true);
    },
  },

  mounted() {},

  watch: {},
};
</script>
