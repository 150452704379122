<template>
    <div class="elite-tabs-wrapper-content signatures">
    <div v-if="loading" class="table-loading" slot="empty">
      <img src="/img/loading.gif" />
    </div>
    <div v-if="!loading"  class="page-wrapper-table mt-3">
      <div class="page-wrapper-table-header">
       <!--  <el-select
          class="select-primary pagination-select"
          v-model="pagination.perPage"
          :placeholder="$t('COMMON.PER_PAGE')"
        >
          <el-option
            class="select-primary"
            v-for="item in pagination.perPageOptions"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select> -->

        <!-- <base-input
          class="form-group-wrapper-item w-25"
          v-model="query"
          type="search"
          prepend-icon="far fa-search"
          :placeholder="$t('COMMON.SEARCH')"
          clearable
        /> -->
        <!-- <base-button
          class="btn base-button notification bg-dark btn-icon btn-fab btn-info btn-sm mb-4"
          icon
          size="sm"
          @click="resetFilters"
        >
          <span class="btn-inner--icon">
            <i class="fa fa-undo"></i>
          </span>
        </base-button> -->
      </div>

      <div class="payment-methods-list-grid-body">
        <div class="payment-methods-list-grid-body-inner">
          <div class="list-grid">
            <!-- Section "Par défaut" -->
            <div class="default-method mt-2" v-if="defaultPaymentMethod">
              <h4>{{$t("PAYMENT_METHODS.DEFAULT")}}</h4>
              <payment-method-card
                :payment-method="defaultPaymentMethod"
                @viewPaymentMethod="viewPaymentMethod"
                @deletePaymentMethod="deletePaymentMethod"
                @editPaymentMethod="editPaymentMethod"
              />
            </div>

            <!-- Section "Autres" -->
            <div class="other-methods mt-2" v-if="otherPaymentMethods.length">
              <h4>{{$t("PAYMENT_METHODS.OTHERS")}}</h4>
              <payment-method-card
                v-for="(method, index) in otherPaymentMethods"
                :key="index"
                :payment-method="method"
                @viewPaymentMethod="viewPaymentMethod"
                @deletePaymentMethod="deletePaymentMethod"
                @editPaymentMethod="editPaymentMethod"
                @setAsDefault="setAsDefault"
              />
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="page-wrapper-table-footer">
        <p class="card-category">
          {{
            $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
              from: total ? from + 1 : 0,
              to: to,
              of: total,
            })
          }}
          <span v-if="selectedRows.length">
            &nbsp; &nbsp;
            {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
          </span>
        </p>
        <base-pagination
          class="pagination-no-border"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
        />
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Option,
  Select,
  Table,
  TableColumn,
  Tooltip,
} from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { BasePagination } from "@/components";
import PaymentMethodCard from "../components/PaymentMethodCard.vue";

export default {
  name: "PaymentMethodsListGridView",

  components: {
    BasePagination,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    PaymentMethodCard,
  },

  mixins: [requestErrorMixin],

  props: ["holderType", "holderId"],

  data() {
    return {
      query: null,
      selectedRows: [],
      sort: "-created_at",
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      paymentMethods: [],
      loading: false,
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    // Renvoie le moyen de paiement par défaut
    defaultPaymentMethod() {
      return this.paymentMethods.find((method) => method.is_default);
    },
    // Renvoie les autres moyens de paiement
    otherPaymentMethods() {
      return this.paymentMethods.filter((method) => !method.is_default);
    },
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
  },

  methods: {
    resetFilters() {
      this.query = null;
    },

    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      try {
        this.loading = true;
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { search: this.query } : {}),
            ...(this.holderType ? { holder_type: this.holderType } : {}),
            ...(this.holderId ? { holder_id: this.holderId } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
        };

        await this.$store.dispatch("paymentMethods/list", params);
        this.paymentMethods = this.$store.getters["paymentMethods/list"];
        this.total = this.$store.getters["paymentMethods/listTotal"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    editPaymentMethod(paymentMethod) {
      this.$emit("onEditPaymentMethod", paymentMethod);
    },

    async deletePaymentMethod(paymentMethod) {
      let id = paymentMethod.id;
      const confirmation = await swal.fire({
        text: this.$t("PAYMENT_METHODS.DELETE_THIS_PAYMENT_METHOD"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonText:
          this.$t("COMMON.YES") + ", " + this.$t("COMMON.DELETE"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("paymentMethods/destroy", id);
          await this.getList();
          this.$notify({
            type: "success",
            message: this.$t("PAYMENT_METHODS.PAYMENT_METHOD_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    viewPaymentMethod(paymentMethod) {
      this.$emit("onViewPaymentMethod", paymentMethod);
    },

    setAsDefault(paymentMethod) {
      this.$emit("onSetAsDefault", paymentMethod);
    },
    
    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
  },
};
</script>
