import {PM_TYPE_BANK_ACCOUNT} from "@/constants/paymentMethods"
export default {
  type: "payment-methods",
  payment_method_type: PM_TYPE_BANK_ACCOUNT,
  elixio_token: null,
  status: null,
  holder_type: null,
  holder_id: null,
  details: {
    account_number: null,
    branch_code: null,
    transit_number: null,
    bank_name: null,
    bank_acronym: null,
    holder: {
      email: null,
      firstname: null,
      lastname: null,
      address: null,
      phone: null,
    },
  },
  signers:[],
  error_message: null,
  is_active: true,
  is_default: false,
  relationshipNames: ["contract","debitContract","creditContract","signatures"],
  contract: {
    type: "contracts",
    id: null,
  },
  debitContract: {
    type: "contracts",
    id: null,
  },
  creditContract: {
    type: "contracts",
    id: null,
  },
  signatures: [],
  email_message:null,
};
