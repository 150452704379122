<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!paymentMethod">
      <span class="loader"></span>
    </span>
    <div v-if="paymentMethod" class="elite-tabs-wrapper">
      <tabs
        fill
        class="flex-column flex-md-row"
        tabNavWrapperClasses="nav-wrapper"
        tabNavClasses="nav elite-tabs"
        value="label"
      >
        <tab-pane title="label" id="1" :active="true">
          <span slot="title">
            <i class="ni ni-cloud-upload-96"></i>
            {{ $t("COMMON.GLOBAL") }}
          </span>
          <payment-method-view-global
            :paymentMethod="paymentMethod"
            @onPaymentMethodUpdated="get"
            @onEditPaymentMethod="editPaymentMethod"
            @onDeletePaymentMethod="deletePaymentMethod"
            @onSetAsDefault="setAsDefault"
            @onActivateOrDeactivate="activateOrDeactivate"
          />
        </tab-pane>

        
        <tab-pane title="label" id="2" v-if="paymentMethod.signatures?.length">
          <span slot="title">
            <i class="fa fa-pencil"></i>
            {{ $t("SIGNATURES.SIGNATURES") }}
          </span>
          <signature-list-grid-view
            object-type="PaymentMethod"
            :object-id="paymentMethod.id"
            :signatures="paymentMethod.signatures"
          />
        </tab-pane>

        <tab-pane
          title="logs"
          id="999"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
        >
          <span slot="title">
            <i class="fa fa-file"></i>
            {{ $t("COMMON.LOGS") }}
          </span>
          <payment-method-view-logs :paymentMethod="paymentMethod" />
        </tab-pane>
      </tabs>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { Tabs, TabPane } from "@/components";
import PaymentMethodViewLogs from "../partials/PaymentMethodViewLogs.vue";
import PaymentMethodViewGlobal from "../partials/PaymentMethodViewGlobal.vue";
import SignatureListGridView from "../../SignatureManagement/partials/SignatureListGridView.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    PaymentMethodViewLogs,
    PaymentMethodViewGlobal,
    SignatureListGridView,
  },

  mixins: [],

  props: {
    paymentMethodId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      paymentMethod: null,
    };
  },

  computed: {},

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        await this.$store.dispatch("paymentMethods/get", this.paymentMethodId);
        this.paymentMethod =
          this.$store.getters["paymentMethods/paymentMethod"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
    editPaymentMethod(paymentMethod) {
      this.$emit("onEditPaymentMethod", paymentMethod);
    },
    deletePaymentMethod(paymentMethod) {
      this.$emit("onDeletePaymentMethod", paymentMethod);
    },
    setAsDefault(paymentMethod) {
      this.$emit("onSetAsDefault", paymentMethod);
    },
    activateOrDeactivate(paymentMethod) {
      this.$emit("onActivateOrDeactivate", paymentMethod);
    },
  },
};
</script>
