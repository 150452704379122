<template>
  <div class="contract-selection">
    <el-radio-group v-model="selectedContract" size="medium" class="w-100">
      <div
        v-for="contract in contracts"
        :key="contract.id"
        @click="selectContract(contract.id, contract.undetermined)"
        :class="[
          'contract-card',
          { selected: selectedContract === contract.id },
        ]"
        class="mb-3"
      >
        <el-card shadow="always" class="contract-card-content">
          <div class="d-flex align-items-start">
            <!-- Icône devant le texte -->
            <div class="icon-container">
              <i class="fas fa-file"></i>
            </div>
            <!-- Texte du contrat -->
            <div>
              <h4 class="contract-title">{{ contract.name }}</h4>
              <p class="contract-description">{{ contract.excerpt }}</p>
            </div>
          </div>
        </el-card>
      </div>
    </el-radio-group>
  </div>
</template>

<script>
import { RadioGroup, Radio, Card } from "element-ui";
export default {
  name: "ContractSelection",
  components: {
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Card.name]: Card,
  },
  props: {
    initialSelected: {
      type: String,
      default: null, // Initial contract type (optional)
    },
    contract: {
      type: [String, Array],
      default: null,
    },
    contractType: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      sort: "-created_at",
      contracts: [], // Liste des contracts récupérées
      loading: false, // Indicateur de contractment
      selectedContract: this.initialSelected, // Two-way binding with the selected contract
      selectedContractUndetermined: 0,
    };
  },

  created() {
    this.getList(null, this.contract); // Charger les données initiales
  },

  methods: {
    selectContract(contractId, undetermined) {
      this.selectedContract = contractId;
      this.selectedContractUndetermined = undetermined;
    },
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),
    // Fonction pour récupérer les contracts avec support pour tableau d'IDs ou ID unique
    async getList(query = null, ids = null) {
      try {
        this.loading = true;
        let params = {
          sort: this.sort,
          filter: {
            ...(query ? { search: query } : {}),
            ...(Array.isArray(ids) ? { ids: ids } : ids ? { id: ids } : {}),
          },
          page: {
            number: 1,
            size: 10,
          },
        };

        if (this.contractType) {
          params.filter.contract_type = this.contractType;
        }

        await this.$store.dispatch("contracts/list", params);
        const contractsArr = await this.$store.getters["contracts/list"];
        this.contracts = contractsArr;
      } catch (error) {
        console.log(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.loading = false;
      }
    },
  },

  watch: {
    selectedContract(newValue) {
      let contract = {
        id: newValue,
        undetermined: this.selectedContractUndetermined,
      };
      // Emit the selected contract type for use in parent components
      this.$emit("contract-selected", contract);
    },
    contractType: {
      handler: "getListDebounced",
      immediate: true,
    },
  },
};
</script>
