export const DPA_CONTRACT_TYPE = 'DPA';
export const DPA_CREDIT_CONTRACT_TYPE = 'DPA_CREDIT';
export const DPA_DEBIT_CONTRACT_TYPE = 'DPA_DEBIT';
export const OTHERS_CONTRACT_TYPE = "OTHERS";
export const CONTRACT_TYPES = {
    '':'',
    [DPA_CREDIT_CONTRACT_TYPE]: "DPA_CREDIT",
    [DPA_DEBIT_CONTRACT_TYPE]: "DPA_DEBIT",
    [DPA_CONTRACT_TYPE]: "DPA",
    [OTHERS_CONTRACT_TYPE]: "OTHERS",
};

export const SUBTYPE_FULLNAME = 'FULLNAME';
export const SUBTYPE_INITIALS = 'INITIALS';
export const SUBTYPE_CAPTURE = 'CAPTURE';
export const SUBTYPE_LABEL = 'LABEL';
export const SUBTYPE_TEXTFIELD = 'TEXTFIELD';
export const SUBTYPE_TEXTAREA = 'TEXTAREA';
export const SUBTYPE_CHECKBOX = 'CHECKBOX';
export const SUBTYPE_DATE = 'DATE';
export const SUBTYPE_RADIO = 'RADIO';
export const SUBTYPE_LIST = 'LIST';
export const SUBTYPE_QRCODE = 'QRCODE';
export const SUBTYPE_CUSTOMFIELD = 'CUSTOMFIELD';
export const SUBTYPE_SEAL = 'SEAL';
export const SUBTYPE_MOBILE_CAPTURE = 'MOBILE_CAPTURE';
export const SUBTYPE_RAW_CAPTURE = 'RAW_CAPTURE';
export const SUBTYPE_DATEPICKER = 'DATEPICKER';

export const SIGNATURE_SUBTYPES = {
    '':'',
    [SUBTYPE_CAPTURE]:'CAPTURE',
    [SUBTYPE_FULLNAME]: "FULLNAME",
    [SUBTYPE_INITIALS]: "INITIALS",
    [SUBTYPE_SEAL]:'SEAL',
    [SUBTYPE_TEXTFIELD]:'TEXTFIELD',
    [SUBTYPE_TEXTAREA]: 'TEXTAREA',
    /* [SUBTYPE_MOBILE_CAPTURE]: 'MOBILE_CAPTURE',
    [SUBTYPE_LABEL]:'LABEL',
    [SUBTYPE_CHECKBOX]: 'CHECKBOX',
    [SUBTYPE_DATE]:'DATE',
    [SUBTYPE_RADIO]: 'RADIO',
    [SUBTYPE_LIST]:'LIST',
    [SUBTYPE_QRCODE]: 'QRCODE',
    [SUBTYPE_CUSTOMFIELD]:'CUSTOMFIELD',
    [SUBTYPE_RAW_CAPTURE]: 'RAW_CAPTURE',
    [SUBTYPE_DATEPICKER]: 'DATEPICKER', */
};