<template>
  <div>
    <div class="page-wrapper-table">
      <div class="page-wrapper-table-body">
        <div class="page-wrapper-table-body-inner with-icones">
          <el-table
            class="align-items-center table-flush"
            header-row-class-name="thead-light"
            :data="contractFiles"
            @sort-change="sortChange"
          >
            <div class="table-loading" slot="empty" v-if="loading">
              <img src="/img/loading.gif" style="height: 100px; width: 100px" />
            </div>

            <el-table-column
              label=""
              prop="file_type"
              min-width="50"
            >
              <template v-slot="{ row }">
                <i v-if="row.file_type === 'pdf'" class="fas fa-file-pdf"></i>
                <i v-else-if="row.file_type === 'word'" class="fas fa-file-word"></i>
                <i v-else-if="row.file_type === 'excel'" class="fas fa-file-excel"></i>
                <i v-else-if="row.file_type === 'image'" class="fas fa-file-image"></i>
                <i v-else-if="row.file_type === 'text'" class="fas fa-file-alt"></i>
                <i v-else class="fas fa-file"></i> <!-- Par défaut -->
              </template>
            </el-table-column>

            <el-table-column
              :label="$t('COMMON.NAME')"
              prop="name"
              sortable="custom"
              min-width="150"
            >
              <template v-slot="{ row }">
                <a :href="row.url??'#'" target="_blank">{{ row.name }}</a>
              </template>
            </el-table-column>

            <el-table-column
              :label="$t('CONTRACTS.SIGNATURE_TYPE')"
              prop="signature_type"
              min-width="150"
            >
              <template v-slot="{ row }">
                {{row.signature_type ? $t('CONTRACTS.' + row.signature_type) : ''}}
              </template>
            </el-table-column>


           <!-- <el-table-column :label="$t('COMMON.EXCERPT')" prop="excerpt">
              <template v-slot="{ row }">
                {{row.excerpt}}
              </template>
            </el-table-column>
 -->

            <el-table-column fixed="right" min-width="120">
              <div slot-scope="{ row }" class="table-actions">
                <el-tooltip
                  :content="$t('COMMON.VIEW')"
                  placement="top"
                  v-if="$currentUserCan($permissions.PERM_VIEW_CONTRACT_FILES)"
                >
                  <a
                    type="text"
                    @click="viewFile(row.id)"
                    class="table-action"
                    data-toggle="tooltip"
                    style="cursor: pointer"
                  >
                    <i class="fal fa-expand-alt"></i>
                  </a>
                </el-tooltip>
              </div>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <file-view-modal
      v-if="contract.id"
      :showModal="showViewModal"
      :fileData="file"
      @onCloseModal="closeModal"
      :show-options="false"
    />

  </div>
</template>
<script>
import _ from "lodash";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import "sweetalert2/dist/sweetalert2.css";
import requestErrorMixin from "@/mixins/request-error-mixin";
import models from "@/constants/models";
import FileViewModal from "./FileViewModal.vue";
import defaultContractFile from "../defaultContractFile";
import { cloneDeep } from "lodash";
import swal from "sweetalert2";

export default {
  name: "FileListComponent",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    FileViewModal
  },

  mixins: [requestErrorMixin],

  props: {
    contract: {
      type: Object,
      default: null,
      description: "Files linked contract",
    },
  },

  data() {
    return {
      file: cloneDeep(defaultContractFile),
      showViewModal: false,
      query: null,
      selectedRows: [],
      sort: "-created_at",
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      contractFiles: [],
      loading: true,
      selectedOrganization: null,
      selectedLocations: null,
      selectedObjectType: null,
      selectedTags: [],
      models: models,
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    contact: {
      handler: "getListDebounced",
      immediate: true,
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      try {
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { search: this.query } : {}),
            ...(this.contract.id ? { contract_id : this.contract.id } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
        };
        await this.$store.dispatch("contractFiles/list", params);
        this.contractFiles = this.$store.getters["contractFiles/list"];
        this.total = this.$store.getters["contractFiles/listTotal"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async viewFile(id) {
      this.openViewFileModal(id);
    },

    async openViewFileModal(id) {
      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });
      await this.$store.dispatch("contractFiles/get", id);
      this.file = this.$store.getters["contractFiles/file"];
      this.showViewModal = true;
      swal.close();
    },

    closeModal(refresh = false) {
      this.showAddFormModal = false;
      this.showEditFormModal = false;
      this.showViewModal = false;
      this.file = cloneDeep(defaultContractFile);
      if (refresh) {
        this.renderKey = this.renderKey + 1;
      }
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
  },
};
</script>
